<template>
  <div class="page-wrapper">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-xxl-5 col-xl-6 col-lg-7 col-md-8">
          <div class="otp-card">
            <div class="otp-head text-center">
              <h1>Default OTPs</h1>
              <p>These are default OTPs that can be used for login and reset password.</p>
            </div>
            <div class="otp-body">
              <ul v-if="rows.length">
                <li
                  v-for="(row, index) in rows"
                  :key="index"
                  :class="status[row.is_used]"
                >
                  <span>{{ row.otp }}</span>
                </li>
              </ul>
              <hr />
            </div>
            <div class="mt-2">
              <button class="univ-btn mw-100 d-block" @click="regenerateOtp">
                Regenerate OTP
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
const STATUS = {
  0: "is-usable",
  1: "is-nonusable",
};
export default {
  data: () => ({
    rows: [],
    status: STATUS,
  }),
  methods: {
    getDefaultOtp() {
      const url = "v2/default-otp/get";
      let loader = this.$loading.show();
      Api.get(url)
        .then((res) => {
          this.rows = res.data.data;
        })
        .catch(() => {
          this.rows = [];
        })
        .finally(() => {
          loader.hide();
        });
    },
    regenerateOtp() {
      const url = "v2/default-otp/set";
      let loader = this.$loading.show();
      Api.post(url)
        .then((res) => {
          this.rows = res.data.data;
          this.$notify(res.data.message);
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
  created() {
    this.getDefaultOtp();
  },
};
</script>
<style scoped>
.otp-card {
  background: #fff;
  box-shadow: 0 2px 15px rgb(216 227 235 / 33%);
  border-radius: 10px;
  padding: 2rem;
}

.otp-head {
  margin-bottom: 2rem;
}

.otp-head h1 {
  font-size: 20px;
  font-weight: 700;
}

.otp-head p {
  color: #778699;
  font-size: 14px;
  margin: 1rem 0;
}

.otp-body ul {
  margin: 0;
  padding: 0;
}
.otp-body ul li {
  list-style: none;
  padding: 10px 8px;
  margin-bottom: 1rem;
  border-radius: 8px;
  font-size: 15px;
  letter-spacing: 3px;
}
.is-usable {
  background: rgba(41, 188, 255, 0.1) !important;
  color: #03c3ec;
}

.is-nonusable {
  background: #fdeeed !important;
  color: #ea584e;
}
</style>