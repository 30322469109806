var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-xxl-5 col-xl-6 col-lg-7 col-md-8"
  }, [_c('div', {
    staticClass: "otp-card"
  }, [_vm._m(0), _c('div', {
    staticClass: "otp-body"
  }, [_vm.rows.length ? _c('ul', _vm._l(_vm.rows, function (row, index) {
    return _c('li', {
      key: index,
      class: _vm.status[row.is_used]
    }, [_c('span', [_vm._v(_vm._s(row.otp))])]);
  }), 0) : _vm._e(), _c('hr')]), _c('div', {
    staticClass: "mt-2"
  }, [_c('button', {
    staticClass: "univ-btn mw-100 d-block",
    on: {
      "click": _vm.regenerateOtp
    }
  }, [_vm._v(" Regenerate OTP ")])])])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "otp-head text-center"
  }, [_c('h1', [_vm._v("Default OTPs")]), _c('p', [_vm._v("These are default OTPs that can be used for login and reset password.")])]);
}]

export { render, staticRenderFns }